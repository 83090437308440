import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './Assessment.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteAssessment, getAssessment, listAssessmentDomains, listAssessments, listAssessmentUserSurveys, updateAssessment } from '../../services/assessment';
import { getUserInfo, handleHttpError } from '../../services/api';
import { AssessmentData, AssessmentDomainData } from '../../types/assessment';
import { DataGrid } from '../../components/DataGrid';
import { DataGridColumn } from '../../components/DataGrid/DataGrid';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import { TextInput } from '../../components/TextInput';
import { RateData } from '../../types/rate';
import { getRate } from '../../services/rate';
import { Button } from '../../components/Button';
import { ProgressBar } from '../../components/ProgressBar';
import { ReactComponent as ArrowRightIcon } from '../../assets/img/list-arrow.svg';
import { ReactComponent as PayIcon } from '../../assets/img/pay.svg';
import { ReactComponent as PersonsIcon } from '../../assets/img/persons.svg';
import { ReactComponent as DropDownIcon } from '../../assets/img/dropdown-toggle.svg';
import { UserData, UserSurveyData, UserSurveyStatusData } from '../../types/user';
import { DomainData } from '../../types/domain';
import { listDomains } from '../../services/domain';
import { blockUserSurveys, createUser, createUserSurvey, deleteUserSurveys, downloadUsers, downloadUsersTpl, listUserSurveyStatuses, unblockUserSurveys, updateUser, updateUserSurvey, uploadUsers } from '../../services/user';
import Moment from 'moment';
import { ModalDlg } from '../../components/ModalDlg';
import { raiseNotice } from '../../components/Notices/Notices';
import { validateReqEmail, validateReqFIO, validateRequired, validateTime } from '../../services/utils';
import { AssessmentStatus } from '../../components/AssessmentStatus';
import { Form } from '../../components/Form';
import Dropzone from 'react-dropzone';
import { Oval } from 'react-loader-spinner'
import { AssessmentActions } from '../../components/AssessmentActions';

export function Assessment() {
    require('../../assets/css/page.css');

    const navigate = useNavigate();

    const [assessmentId, setAssessmentId] = useState<number|undefined>(undefined);
    const [data, setData] = useState<AssessmentData|undefined>(undefined);
    const [rate, setRate] = useState<RateData|undefined>(undefined);
    const [assessmentDomains, setAssessmentDomains] = useState<AssessmentDomainData[]>([]);
    const [domains, setDomains] = useState<DomainData[]>([]);
    const [userSurveys, setUserSurveys] = useState<UserSurveyData[]>([]);
    const [domainRows, setDomainRows] = useState<any[]>([]);
    const [respondentRows, setRespondentRows] = useState<any[]>([]);
    const [respondentInterviewRows, setRespondentInterviewRows] = useState<any[]>([]);
    const [respondentSelectedIds, setRespondentSelectedIds] = useState<string[]>([]);
    const [respondentInterviewSelectedIds, setRespondentInterviewSelectedIds] = useState<string[]>([]);
    const [userSurveyStatuses, setUserSurveyStatuses] = useState<UserSurveyStatusData[]>([]);

    type EditUserSurveyData = {
        user_survey_id?: number;
        user_id?: string;
        name?: string;
        login?: string;
        assessment_domain_id?: number;
        is_head: boolean;
        is_interviewee: boolean;
    };

    type EditUserSurveyInterviewData = {
        user_survey_id?: number;
        user_id?: string;
        name?: string;
        login?: string;
        assessment_domain_id?: number;
        is_interviewee: boolean;
        department?: string;
        position?: string;
        meeting_date?: Date;
        meeting_time?: string;
    };

    const [editUserSurveyData, setEditUserSurveyData] = useState<EditUserSurveyData>({ is_head: false, is_interviewee: false });
    const [editUserSurveyInterviewData, setEditUserSurveyInterviewData] = useState<EditUserSurveyInterviewData>({ is_interviewee: true });

    const [showModalResp, setShowModalResp] = useState<boolean>(false);
    const [showModalAddResp, setShowModalAddResp] = useState<boolean>(false);
    const [modalAddRespIsLoading, setModalAddRespIsLoading] = useState<boolean>(false);
    const [showModalRespInterview, setShowModalRespInterview] = useState<boolean>(false);
    const [mode, setMode] = useState<string>('survey');

    const [uploadNotices, setUploadNotices] = useState<any[]>([]);

    const { id } = useParams();

    const editRespondent = (row: any) => {
        if (row.is_interviewee) {

            const d = row.interview_date ? new Date(row.interview_date) : undefined;
            setEditUserSurveyInterviewData({ user_survey_id: row.id, user_id: row.user_id, name: row.fio, login: row.email, assessment_domain_id: row.assessment_domain_id, is_interviewee: row.is_interviewee, department: row.department, position: row.position, meeting_date: row.interview_date, meeting_time: d ? d.getHours().toString().padStart(2, '0') + ':' + d.getMinutes().toString().padStart(2, '0') : '' });
            setShowModalRespInterview(true);
        } else {
            setEditUserSurveyData({ user_survey_id: row.id, user_id: row.user_id, name: row.fio, login: row.email, assessment_domain_id: row.assessment_domain_id, is_head: row.is_head, is_interviewee: row.is_interviewee });
            setShowModalResp(true);
        }
    }

    const respondentGridCols:DataGridColumn[] = [
        { type: 'selector' },
        { type: 'string', title: '№', property: 'num' },
        { type: 'string', title: 'ФИО', property: 'fio', 
            renderHeader: () => { return 'ФИО<small>Email</small>' },
            renderNode: (row: any) => <div><a className={styles.fio_link} onClick={() => editRespondent(row)}>{row.fio}</a><small>{row.email}</small></div>
        },
        { type: 'string', title: 'Домен', property: 'domain' },
        { type: 'string', title: 'Статус анкеты', property: 'survey_status', 
            renderHeader: () => { return 'Статус анкеты<small>Дата и время</small>' },
            renderNode: (row: any) => <div>{row.survey_status}<small>{row.survey_date}</small></div>
        },
        { type: 'string', title: 'Статус ссылки', property: 'link_status', 
            renderHeader: () => { return 'Статус ссылки<small>ссылка на опрос</small>' },
            renderNode: (row: any) => <div>{row.link_status}{row.url && row.status_id == 1 && (<a target='_blank' href={row.url} onClick={(e) => { e.preventDefault(); e.stopPropagation(); navigator.clipboard.writeText((e.target as any).href); raiseNotice('info', 'Ссылка скопирована'); }}>скопировать ссылку</a>)}</div>
        },
        { type: 'string', title: 'Количество ответов', property: 'num_answers', renderHeader: () => { return 'Количество ответов<small>Дата и время последнего ответа</small>' },
            renderNode: (row: any) => <div>{row.total_answers} / {row.total_questions}</div>
        }
    ];

    const renderInterviewStatus = (row: any) => {
        var s;
        if (row.status_id == 1) {
            s = (row.interview_date ? 'назначено' : 'не назначено')
        } else if (row.status_id == 2) {
            s = 'не состоялось';
        } else if (row.status_id == 3) {
            s = 'проведено';
        } else
            s = userSurveyStatuses.find(r => r.id == row.status_id)?.name;
        return <div>{s}</div>;
    }

    const respondentInterviewGridCols:DataGridColumn[] = [
        { type: 'selector' },
        { type: 'string', title: '№', property: 'num' },
        { type: 'string', title: 'ФИО', property: 'fio', 
            renderHeader: () => { return 'ФИО<small>Должность/подразделение</small>' },
            renderNode: (row: any) => <div><a className={styles.fio_link} onClick={() => editRespondent(row)}>{row.fio}</a><small>{row.position} / {row.department}</small></div>
        },
        { type: 'string', title: 'E-mail', property: 'email' },
        { type: 'string', title: 'Домен', property: 'domain' },
        { type: 'string', title: 'Дата и время интервью', property: 'interview_date', 
            renderNode: (row: any) => <div>{row.interview_date ? Moment(row.interview_date).format('DD.MM.YYYY H:mm') : '-'}</div>
        },
        { type: 'string', title: 'Статус интервью', property: 'status_id', 
            renderNode: renderInterviewStatus
        }
    ];

    const domainGridCols:DataGridColumn[] = [
        { type: 'selector' },
        { type: 'string', title: '№', property: 'num' },
        { type: 'string', title: 'Название домена', property: 'name' },
        { type: 'string', title: 'Всего респондентов анкет', property: 'total_users' },
        { type: 'string', title: 'Релевантных анкет', property: 'surveys' },
        { type: 'string', title: 'Всего респондентов интервью', property: 'total_users_interview' },
        { type: 'string', title: 'Проведенных интервью', property: 'completed' }
    ];

    useEffect(() => {
        listDomains(0, 1000).then(res => {
            setDomains(res);
        }).catch(handleHttpError);
    }, []);

    useEffect(() => {
        if (id) setAssessmentId(parseInt(id));
      }, [id]);

    const loadUserSurveys = () => {
        if (assessmentId) {
            listAssessmentUserSurveys(0, 1000, assessmentId).then(res => {
                setUserSurveys(res);
            }).catch(handleHttpError);
        }
    };

    const loadData = () => {
        if (assessmentId) {
            getAssessment(assessmentId).then(res => {
                setData(res);
            }).catch(handleHttpError);
        }
    }

    useEffect(() => {
        if (assessmentId) {
            getUserInfo().then(user => {
                if (!user)
                    window.location.href = '/rdc-admin';
                else {
                    loadData();

                    listAssessmentDomains(0, 1000, assessmentId).then(res => {
                        setAssessmentDomains(res);
                    }).catch(handleHttpError);

                    loadUserSurveys();

                    listUserSurveyStatuses().then(res => {
                        setUserSurveyStatuses(res);
                    }).catch(handleHttpError);
                }
            }).catch(handleHttpError);
            
        }
    }, [ assessmentId ]);

    useEffect(() => {
        if (data && data.rate_id) {
            getRate(data.rate_id).then(res => {
                setRate(res);
            }).catch(handleHttpError);
        }
    }, [ data?.rate_id ]);

    useEffect(() => {
        if (domains && assessmentDomains) {
            setDomainRows(assessmentDomains.map(ad => ({ id: ad.id, num: domains.find(d => d.id == ad.domain_id)?.num, name: domains.find(d => d.id == ad.domain_id)?.name, total_users: ad.total_users, surveys: 0, total_users_interview: ad.total_users_interview, completed: 0 })));
        } else
            setDomainRows([]);
    }, [ domains, assessmentDomains ]);

    useEffect(() => {
        if (userSurveys) {
            var n1 = 1;
            var n2 = 1;
            setRespondentRows(userSurveys.filter(us => !us.is_interviewee).map(us => ({ 
                id: us.id, 
                num: n1++,
                fio: us.user?.name, 
                email: us.user?.login, 
                domain: domains.find(d => d.id == ( assessmentDomains.find(ad => ad.id == us.assessment_domain_id)?.domain_id ))?.name,
                survey_status: userSurveyStatuses.find(s => s.id == us.status_id)?.name,
                survey_date: us.date ? Moment(us.date).format('DD.MM.YYYY') : '',
                link_status: '-',
                url: us.url,
                num_answers: '0 / 0',
                user_id: us.user_id,
                is_interviewee: us.is_interviewee,
                assessment_domain_id: us.assessment_domain_id,
                is_head: us.user?.is_head,
                department: us.department,
                position: us.position,
                date: us.date,
                status_id: us.status_id,
                total_questions: us.total_questions,
                total_answers: us.total_answers
            })));
            setRespondentInterviewRows(userSurveys.filter(us => us.is_interviewee).map(us => ({ 
                id: us.id,
                num: n2++, 
                fio: us.user?.name, 
                email: us.user?.login, 
                domain: domains.find(d => d.id == ( assessmentDomains.find(ad => ad.id == us.assessment_domain_id)?.domain_id ))?.name,
                survey_status: us.status_id,
                survey_date: us.date ? Moment(us.date).format('DD.MM.YYYY') : '',
                link_status: '-',
                link: us.url,
                num_answers: '0 / 0',
                user_id: us.user_id,
                is_interviewee: us.is_interviewee,
                assessment_domain_id: us.assessment_domain_id,
                is_head: us.user?.is_head,
                department: us.department,
                position: us.position,
                date: us.date,
                status_id: us.status_id,
                interview_date: us.interview_date
            })));
        } else {
            setRespondentRows([]);
            setRespondentInterviewRows([]);
        }
    }, [ userSurveys, userSurveyStatuses ]);

    const addOrEditUserSurvey = (data:EditUserSurveyData) => {

        if (rate) 
        {
            if (data.user_survey_id) {
                updateUser({ id: data.user_id, name: data.name, login: data.login, is_valid: true, is_head: data.is_head }).then(usr => {
                    if (usr.id) {
                        updateUserSurvey({ id: data.user_survey_id, user_id: usr.id, assessment_domain_id: data.assessment_domain_id, is_interviewee: data.is_interviewee }).then(us => {
                            loadUserSurveys();
                            setShowModalResp(false);

                            if (us.id) {
                                raiseNotice('info', 'Изменен респондент ' + data.name);
                            }
                        }).catch(handleHttpError);
                    }
                }).catch(handleHttpError);
            } else {
                createUser({ name: data.name, login: data.login, is_valid: true, is_head: data.is_head }).then(usr => {
                    if (usr.id) {
                        createUserSurvey({user_id: usr.id, survey_id: rate.survey_id ?? '', status_id: 1, assessment_domain_id: data.assessment_domain_id, is_interviewee: data.is_interviewee }).then(us => {
                            loadUserSurveys();
                            setShowModalResp(false);
                            
                            if (us.id) {
                                raiseNotice('info', 'Добавлен респондент ' + data.name)
                            }
                        }).catch(handleHttpError);
                    }
                }).catch(handleHttpError);
            }
        }
    }

    const addOrEditUserSurveyInterview = (data:EditUserSurveyInterviewData) => {

        if (rate) 
        {
            const int_date = (data.meeting_date && data.meeting_time) ? (Moment(data.meeting_date).format('YYYY-MM-DD') + 'T' + data.meeting_time.padStart(5, '0')) : '1970-01-01T00:00:00';
            if (data.user_survey_id) {
                updateUser({ id: data.user_id, name: data.name, login: data.login, is_valid: true, is_head: true }).then(usr => {
                    if (usr.id) {
                        updateUserSurvey({ id: data.user_survey_id, user_id: usr.id, assessment_domain_id: data.assessment_domain_id, is_interviewee: data.is_interviewee, department: data.department, position: data.position, interview_date: int_date }).then(us => {
                            loadData();
                            loadUserSurveys();
                            setShowModalRespInterview(false);

                            if (us.id) {
                                raiseNotice('info', 'Изменен респондент ' + data.name);
                            }
                        }).catch(handleHttpError);
                    }
                }).catch(handleHttpError);
            } else {
                createUser({ name: data.name, login: data.login, is_valid: true, is_head: true }).then(usr => {
                    if (usr.id) {
                        
                        createUserSurvey({user_id: usr.id, survey_id: rate.survey_id ?? '', status_id: 1, assessment_domain_id: data.assessment_domain_id, is_interviewee: data.is_interviewee, department: data.department, position: data.position, interview_date: int_date }).then(us => {
                            loadData();
                            loadUserSurveys();
                            setShowModalRespInterview(false);
                            
                            if (us.id) {
                                raiseNotice('info', 'Добавлен респондент ' + data.name)
                            }
                        }).catch(handleHttpError);
                    }
                }).catch(handleHttpError);
            }
        }
    }

    const deleteRespondents = () => {
        if (respondentSelectedIds) {
            deleteUserSurveys(respondentSelectedIds).then(res => {
                loadData();
                loadUserSurveys();
                raiseNotice('info', 'Респонденты удалены');
            }).catch(handleHttpError);
        }
    }

    const deleteRespondentsInterview = () => {
        if (respondentInterviewSelectedIds) {
            deleteUserSurveys(respondentInterviewSelectedIds).then(res => {
                loadData();
                loadUserSurveys();
                raiseNotice('info', 'Респонденты удалены');
            }).catch(handleHttpError);
        }
    }

    const blockRespondents = () => {
        if (respondentSelectedIds) {
            blockUserSurveys(respondentSelectedIds).then(res => {
                if (res) {
                    loadData();
                    loadUserSurveys();
                    raiseNotice('info', 'Респонденты заблокированы');
                }
            }).catch(handleHttpError);
        }
    }

    const unblockRespondents = () => {
        if (respondentSelectedIds) {
            unblockUserSurveys(respondentSelectedIds).then(res => {
                loadData();
                loadUserSurveys();
                raiseNotice('info', 'Респонденты активированы');
            }).catch(handleHttpError);
        }
    }

    const handleUploadError = (type:string, msg: string) => {
        setUploadNotices((prev) => ([...prev, {type: type, msg: msg}]));
    }

    const uploadRespFiles = (acceptedFiles:File[]) => {
        setUploadNotices([]);
        if (assessmentId) {
            setModalAddRespIsLoading(true);

            acceptedFiles.forEach(f => {
                uploadUsers(assessmentId, f, handleUploadError).then(res => {
                    setUploadNotices((prev) => ([...prev, {type: 'info', msg: 'Файл загружен'}]));
                    setModalAddRespIsLoading(false);

                    loadUserSurveys();
                }); //.catch(handleHttpError);
            });
        }
    }

    return (
        <>
        {(!data || !rate) ? (<div>Загрузка...</div>) : (
        <div className={styles.assessment_page}>
            <div className={styles.header_block}>
                <h1>{data.name}</h1>
                <div className={styles.chips}>
                    <AssessmentStatus assessment={data} />
                    <div className='tag orange'>Тариф <span className='orangetext'>{rate.name}</span> <ArrowRightIcon /></div>
                </div>
                <div className='sep'></div>
                <AssessmentActions assessment={data} className={styles.btn_actions} onActionDone={(action) => { if (action == 'delete') navigate('/assessments/'); else loadData();} } />
            </div>
            <div className={styles.content_block}>
                <div className={styles.side_block}>
                    <div className={classNames(styles.side_widget, { [styles.active]: mode == 'survey' })} onClick={() => { setMode('survey'); }}>
                        <h3>Анкетирование</h3>
                        <ProgressBar progress={data.total_user_surveys} maxProgress={rate.max_user} className={styles.pbar} color='#1192E8' />
                        <div className={classNames(styles.stats, styles.total)}><div className={styles.label}>Всего</div><div className={styles.value}>{data.total_user_surveys} / {rate.max_user}</div></div>
                        <div className={styles.stats}><div className={styles.label}>Открыто</div><div className={styles.value}>{data.total_user_surveys_active}</div></div>
                        <div className={styles.stats}><div className={styles.label}>Завершено</div><div className={styles.value}>{data.total_user_surveys_completed}</div></div>
                        <div className={styles.horline}></div>
                        <div className={styles.stats}><div className={styles.label}>Релевантных анкет</div><div className={styles.value}>—</div></div>
                    </div>
                    {rate.max_user_interviewee > 0 && (
                        <div className={classNames(styles.side_widget, { [styles.active]: mode == 'interview' })} onClick={() => { setMode('interview'); }}>
                            <h3>Интервью</h3>
                            <ProgressBar progress={data.total_user_interview_surveys} maxProgress={rate.max_user_interviewee} className={styles.pbar} color='#1192E8' />
                            <div className={classNames(styles.stats, styles.total)}><div className={styles.label}>Всего</div><div className={styles.value}>{data.total_user_interview_surveys} / {rate.max_user_interviewee}</div></div>
                            <div className={styles.stats}><div className={styles.label}>Назначено встреч</div><div className={styles.value}>{data.total_interviews_assigned}</div></div>
                            <div className={styles.horline}></div>
                            <div className={styles.stats}><div className={styles.label}>Проведено</div><div className={styles.value}>{data.total_interviews_completed}</div></div>
                        </div>
                    )}
                    <div className={classNames(styles.side_widget, { [styles.active]: mode == 'domains' })} onClick={() => { setMode('domains'); }}>
                        <h3>Домены</h3>
                        <div className={classNames(styles.stats, styles.total)}><div className={styles.label}>Всего</div><div className={styles.value}>{assessmentDomains.length} / {rate.max_domain}</div></div>
                        <div className={styles.stats}><div className={styles.label}>Репрезентативные</div><div className={styles.value}>—</div></div>
                        <div className={styles.horline}></div>
                        <div className={styles.stats}><div className={styles.label}>Релевантные</div><div className={styles.value}>—</div></div>
                    </div>
                </div>
                <div className={styles.main_block}>
                    {mode == 'survey' && (
                    <>
                        <h3>Респонденты анкет</h3>
                        {!data.is_paid && (
                            <div className='big-msg'>
                                <PayIcon />
                                <p>Оплатите тариф для добавления респондентов</p>
                                <Button label='Оплатить тариф' />
                            </div>
                        )}
                        {(data && data.is_paid) && (
                            <>
                            {respondentRows.length == 0 ? (
                                <div className='big-msg'>
                                    <PersonsIcon />
                                    <p>Добавьте респондентов для создания ссылок</p>
                                    <Button label='Добавить респондентов' onClick={() => { setShowModalResp(true);}} />
                                </div>
                            ) : (
                                <div className={styles.respondents}>
                                    <div className={styles.grid_toolbar}>
                                        <Button label='Активировать' type='text-light-grey' onClick={unblockRespondents} />
                                        <Button label='Заблокировать' type='text-light-grey' onClick={blockRespondents} />
                                        <div className='vsep'></div>
                                        <Button label='Удалить' type='text-light-grey' onClick={deleteRespondents} />
                                        <div className='sep'></div>
                                        <Dropdown className={styles.dd_add_resp}>
                                            <Dropdown.Toggle>Добавить</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => { setEditUserSurveyData({ is_head: false, is_interviewee: false }); setShowModalResp(true);}}>респондента</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { setShowModalAddResp(true); }}>списком</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Button label='Скачать' type='orangetext' onClick={() => assessmentId && downloadUsers(assessmentId)} />
                                    </div>
                                    <DataGrid columns={respondentGridCols} data={respondentRows} selectedIds={respondentSelectedIds} onSelected={(id, selected) => { if (selected) setRespondentSelectedIds((prev) => [...prev, id]); else setRespondentSelectedIds((prev) => [...prev.filter(x => x != id)]); }} />
                                </div>
                            )}
                            </>
                        )}
                    </>
                    )}
                    {mode == 'interview' && (
                    <>
                        <h3>Респонденты интервью</h3>
                        {!data.is_paid && (
                            <div className='big-msg'>
                                <PayIcon />
                                <p>Оплатите тариф для добавления респондентов</p>
                                <Button label='Оплатить тариф' />
                            </div>
                        )}
                        {(data && data.is_paid) && (
                            <>
                            {respondentInterviewRows.length == 0 ? (
                                <div className='big-msg'>
                                    <PersonsIcon />
                                    <p>Добавьте респондентов для отслеживания статусов интервью</p>
                                    <Button label='Добавить респондентов' onClick={() => setShowModalRespInterview(true)} />
                                </div>
                            ) : (
                                <div className={styles.respondents}>
                                    <div className={styles.grid_toolbar}>
                                        <Button label='Действие с респондентом' type='text-light-grey' />
                                        <div className='vsep'></div>
                                        <Button label='Удалить' type='text-light-grey' onClick={deleteRespondentsInterview} />
                                        <div className='sep'></div>
                                        <Button label='Добавить' type='orangetext' onClick={() => { setEditUserSurveyInterviewData({ is_interviewee: true }); setShowModalRespInterview(true);}} />
                                    </div>
                                    <DataGrid columns={respondentInterviewGridCols} data={respondentInterviewRows} selectedIds={respondentInterviewSelectedIds} onSelected={(id, selected) => { if (selected) setRespondentInterviewSelectedIds((prev) => [...prev, id]); else setRespondentInterviewSelectedIds((prev) => [...prev.filter(x => x != id)]); }} />
                                </div>
                            )}
                            </>
                        )}
                    </>
                    )}
                    {mode == 'domains' && (
                    <>
                        <h3>Домены</h3>
                        {!data.is_paid && (
                            <div className='big-msg'>
                                <PayIcon />
                                <p>Оплатите тариф для добавления респондентов</p>
                                <Button label='Оплатить тариф' />
                            </div>
                        )}
                        {(data && data.is_paid) && (
                            <>
                            {domainRows.length == 0 ? (
                                <div className='big-msg'>
                                    <PersonsIcon />
                                    <p>Нет доменов</p>
                                </div>
                            ) : (
                                <div className={styles.respondents}>
                                    <div className={styles.grid_toolbar}>
                                        <Button label='Действие с доменом' type='text-light-grey' />
                                        <div className='vsep'></div>
                                        <Button label='Удалить' type='text-light-grey' />
                                        <div className='sep'></div>
                                    </div>
                                    <DataGrid columns={domainGridCols} data={domainRows} />
                                </div>
                            )}
                            </>
                        )}
                    </>
                    )}
                </div>
            </div>

            

            <Modal show={showModalResp} backdrop={true} onHide={() => setShowModalResp(false)} dialogClassName={styles.dlg_add}>
                <Modal.Header closeButton><Modal.Title>{editUserSurveyData.user_survey_id ? 'Редактирование респондента' : 'Добавление респондента'}</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form formData={editUserSurveyData} structure={{
                        body: [
                            {
                                elements: [
                                    { type: 'text', label: 'ФИО', isRequired: true, dataProperty:'name', validate: validateReqFIO },
                                    { type: 'text', label: 'Email', isRequired: true, dataProperty:'login', validate: validateReqEmail },
                                    { type: 'select', label: 'Домен', isRequired: true, dataProperty: 'assessment_domain_id', validate: validateRequired, 
                                        placeholder: 'Выберите...', selectOptions: assessmentDomains.map(ad =>({ value: ad.id, label: domains.find(d => d.id == ad.domain_id)?.name }))
                                    },
                                    { type: 'checkbox', label: 'Руководитель', isRequired: false, dataProperty: 'is_head'}
                                ]
                            }
                        ],
                        footer: { elements: [ { type: 'submit', label: editUserSurveyData.user_survey_id ? 'Редактировать' : 'Добавить' }, { type: 'button', label: 'Отменить', onClick: () => setShowModalResp(false) } ]}
                    }} onSubmit={(data) => { console.log('data', data); addOrEditUserSurvey(data); }} />
                </Modal.Body>
            </Modal>
            <Modal show={showModalRespInterview} backdrop={true} onHide={() => setShowModalRespInterview(false)} dialogClassName={styles.dlg_add}>
                <Modal.Header closeButton><Modal.Title>{editUserSurveyInterviewData.user_survey_id ? 'Редактирование респондента' : 'Добавление респондента'}</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form formData={editUserSurveyInterviewData} structure={{
                        body: [
                            {
                                elements: [
                                    { type: 'text', label: 'ФИО', isRequired: true, dataProperty:'name', validate: validateReqFIO },
                                    { type: 'text', label: 'Email', isRequired: true, dataProperty:'login', validate: validateReqEmail },
                                    { type: 'text', label: 'Должность', isRequired: true, dataProperty:'position', validate: validateRequired },
                                    { type: 'text', label: 'Подразделение', isRequired: true, dataProperty:'department', validate: validateRequired },
                                    { type: 'select', label: 'Домен', isRequired: true, dataProperty: 'assessment_domain_id', validate: validateRequired, 
                                        placeholder: 'Выберите...', selectOptions: assessmentDomains.map(ad =>({ value: ad.id, label: domains.find(d => d.id == ad.domain_id)?.name }))
                                    },
                                    {
                                        type: 'flex', label: '', children: [
                                            { type: 'date', label: 'Дата', isRequired: false, dataProperty:'meeting_date' },
                                            { type: 'text', label: 'Время', isRequired: false, dataProperty:'meeting_time', validate: validateTime },
                                        ]
                                    }
                                ]
                            }
                        ],
                        footer: { elements: [ { type: 'submit', label: editUserSurveyInterviewData.user_survey_id ? 'Редактировать' : 'Добавить' }, { type: 'button', label: 'Отменить', onClick: () => setShowModalRespInterview(false) } ]}
                    }} onSubmit={(data) => { addOrEditUserSurveyInterview(data); }} />
                </Modal.Body>
            </Modal>
            <ModalDlg show={showModalAddResp} onClose={() => setShowModalAddResp(false)} title='Добавление респондентов списком' showButtons={false} dialogClassName={styles.dlg_add_bulk}>
                {modalAddRespIsLoading ? (
                    <Oval width={40} height={40} color='#F75623' wrapperClass='loading_wrap' />
                ) : (
                    <>
                        <div className={styles.upload_notices}>
                            {uploadNotices.map(notice => <div className={classNames(styles.item, styles[notice.type])} dangerouslySetInnerHTML={{__html: notice.msg}}></div>)}
                        </div>
                        <Dropzone onDropAccepted={acceptedFiles => uploadRespFiles(acceptedFiles)} accept={{ "text/csv": [".csv"]}}>
                        {({getRootProps, getInputProps}) => (
                            <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className='dropzone_msg'>Перетащите в эту область или загрузите файл до 10 Мб формата csv, не более 1-го файла</div>
                            </div>
                            </section>
                        )}
                        </Dropzone>
                        <Button type='orangetext' label='Скачать шаблон csv файла' onClick={() => downloadUsersTpl()} />
                    </>
                )}
            </ModalDlg>
        </div>
        )}
        </>
    )
}