import { AssessmentData } from "../types/assessment";
import { fetchApi, handleHttpResponse, requestDelete, requestGet, requestPost } from "./api";

export const createAssessment = async (data: AssessmentData) => {
    return await fetchApi('/v1/assessment/create', requestPost(data)).then(handleHttpResponse);
}

export const listAssessments = async (offset: number, limit: number) => {
    return await fetchApi('/v1/assessment/list', requestGet()).then(handleHttpResponse);
}

export const getAssessment = async (id: number) => {
    return await fetchApi(`/v1/assessment/${id}`, requestGet()).then(handleHttpResponse);
}

export const getAssessmentPub = async (id: number) => {
    return await fetchApi(`/v1/pub/assessment/${id}`, requestGet()).then(handleHttpResponse);
}

export const listAssessmentDomains = async (offset: number, limit: number, assessmentId: number) => {
    return await fetchApi(`/v1/assessment/${assessmentId}/domains`, requestGet()).then(handleHttpResponse);
}

export const listAssessmentUserSurveys = async (offset: number, limit: number, assessmentId: number) => {
    return await fetchApi(`/v1/assessment/${assessmentId}/user_surveys`, requestGet()).then(handleHttpResponse);
}

export const deleteAssessment = async (id: number) => {
    return await fetchApi(`/v1/assessment/${id}`, requestDelete()).then(handleHttpResponse);
}

export const updateAssessment = async (data: AssessmentData) => {
    return await fetchApi(`/v1/assessment/update`, requestPost(data)).then(handleHttpResponse);
}