import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import styles from './UserSurvey.module.scss';
import { commentAnswer, getScales, getSurvey, getSurveyAnswers, postSurveyAnswer } from '../../services/survey';
import { handleHttpError } from '../../services/api';
import { AnswerData, ScaleData, SectionData, SurveyData } from '../../types/survey';
import { SurveySection } from '../../components/SurveySection';
import { ProgressBar } from '../../components/ProgressBar';
import { Button } from '../../components/Button';
import { UserData, UserSurveyData } from '../../types/user';
import { completeUserSurvey, getUser, getUserSurvey, updateUserSurvey } from '../../services/user';
import { ModalDlg } from '../../components/ModalDlg';
import { ReactComponent as SurveyDoneIcon } from '../../assets/img/survey-done.svg';
import { ReactComponent as TglOpenedIcon } from '../../assets/img/section-tgl-opened.svg';
import { ReactComponent as TglClosedIcon } from '../../assets/img/section-tgl-closed.svg';
import { ReactComponent as InfoIcon } from '../../assets/img/info2.svg';
import { ReactComponent as BigInfoIcon } from '../../assets/img/info-orange-2.svg';
import { ReactComponent as CheckGreenIcon } from '../../assets/img/check-green.svg';
import { getAssessmentPub } from '../../services/assessment';
import { AssessmentData } from '../../types/assessment';

export function UserSurvey() {
    require('../../assets/css/page.css');

    const [surveyId, setSurveyId] = useState<string|undefined>(undefined);
    const [assessmentId, setAssessmentId] = useState<number|undefined>(undefined);
    const [userId, setUserId] = useState<string|undefined>(undefined);
    const [userData, setUserData] = useState<UserData>({});
    const [data, setData] = useState<SurveyData>({});
    const [isExpanded, setIsExpanded] = useState<any>({});
    const [totalQuestions, setTotalQuestions] = useState<number>(0);
    const [totalQuestionsAnswered, setTotalQuestionsAnswered] = useState<number>(0);
    const [showRules, setShowRules] = useState<boolean>(false);
    const [scales, setScales] = useState<ScaleData[]>([]);
    const [scalesExpanded, setScalesExpanded] = useState<boolean>(false);
    const [activeScaleId, setActiveScaleId] = useState<number>(-1);
    const [userSurvey, setUserSurvey] = useState<UserSurveyData>();
    const [assessment, setAssessment] = useState<AssessmentData>({});

    const { aId, sId, uId } = useParams();

    useEffect(() => {
        if (sId) setSurveyId(sId);
    }, [sId]);

    useEffect(() => {
        if (aId) setAssessmentId(parseInt(aId));
    }, [aId]);

    useEffect(() => {
        if (uId) setUserId(uId);
    }, [uId]);

    useEffect(() => {
        if (userId) {
            getUser(userId).then(res => {
                setUserData(res);
            }).catch(handleHttpError);
        }
    }, [ userId ])

    const loadUserSurvey = () => {
        if (assessmentId && surveyId && userId) {
            getAssessmentPub(assessmentId).then(res => {
                setAssessment(res);
            }).catch(handleHttpError);
            getUserSurvey(assessmentId, surveyId, userId).then(res => {
                setUserSurvey(res);
            }).catch(handleHttpError);
        }
    }

    useEffect(() => {
        loadUserSurvey();
    }, [ assessmentId, surveyId, userId ]);

    const loadSurvey = () => {
        if (surveyId) {
            getSurvey(surveyId).then(data => {
                    
                setData(data);

                var isExpanded:any = {};
                data.sections.forEach((s:SectionData) => {
                    isExpanded[s.id ?? ''] = true;
                });
                setIsExpanded(isExpanded);
            }).catch(handleHttpError);

            getScales(surveyId).then(res => {
                setScales(res);
            }).catch(handleHttpError);
        }
    }

    useEffect(() => {
        loadSurvey();
    }, [ surveyId ]);

    useEffect(() => {
        if (data && assessmentId && surveyId && userId) {
            getSurveyAnswers(assessmentId, surveyId, userId).then(res => {
                const ans_ids = res.map((a:any) => a.answer_id);
                setData((prev) => ({...prev, sections: [...data.sections?.map(s => ({...s, questions: [...s.questions?.map(q => ({...q, answers: [ ...q.answers?.map(a => ans_ids.includes(a.id) ? {...a, selected: true, comment: res.find((aa:any) => aa.answer_id == a.id)?.comment} : {...a, selected: false}) ?? [] ]})) ?? []]})) ?? [] ]}));
            }).catch(handleHttpError);
        }
    }, [ data.sections?.length ]);

    useEffect(() => {
        if (data) {
            var qTotal = 0;
            var aTotal = 0;
            data.sections?.forEach(section => {
                qTotal += section.questions?.length ?? 0;
                aTotal += section.questions?.filter(q => q.answers?.some(a => a.selected)).length ?? 0;
            });
            setTotalQuestions(qTotal);
            setTotalQuestionsAnswered(aTotal);
        } else {
            setTotalQuestions(0);
            setTotalQuestionsAnswered(0);
        }
    }, [ data ]);

    const expandAll = (v:boolean) => {
        var obj = {...isExpanded};
        for (var k in obj)
            obj[k] = v;
        setIsExpanded(obj);
    }

    const answerSelected = (section_id: number, question_id: number, answer_id: number) => {

        if (assessmentId && surveyId && userId) {
            postSurveyAnswer(assessmentId, surveyId, userId, answer_id).then(res => {
                if (res) {
                    setData((prev) => ({...prev, sections: [...data.sections?.map(s => s.id == section_id ? {...s, questions: [...s.questions?.map(q => q.id == question_id ? {...q, answers: [ ...q.answers?.map(a => a.id == answer_id ? {...a, selected: true} : {...a, selected: false}) ?? [] ]} : q) ?? []]} : s) ?? [] ]}));
                }
             }).catch(handleHttpError);
        }
    }

    const completeSurvey = () => {
        if (assessmentId && surveyId && userId) {
            completeUserSurvey(assessmentId, surveyId, userId).then(res => {
                loadUserSurvey();
            }).catch(handleHttpError);
        }
    }

    const onCommentAnswer = (section_id: number, question_id: number, answer_id: number, comment: string) => {
        if (assessmentId && surveyId && userId) {
            commentAnswer(assessmentId, surveyId, userId, answer_id, comment).then(res => {
                if (res) {
                    setData((prev) => ({...prev, sections: [...data.sections?.map(s => s.id == section_id ? {...s, questions: [...s.questions?.map(q => q.id == question_id ? {...q, answers: [ ...q.answers?.map(a => a.id == answer_id ? {...a, comment: comment} : {...a, selected: false}) ?? [] ]} : q) ?? []]} : s) ?? [] ]}));
                }
            }).catch(handleHttpError);
        }
    }

    return (
        <>
            <header className={styles.header}>
                <div className={styles.site}>
                    <div className={styles.sitename}>БССГ</div>
                    <div className={styles.sitetitle}>Оценка состоятельности<br/>в области управления данными</div>
                </div>
                {userSurvey && userSurvey.status_id == 1 && (
                    <>
                        <div className={styles.user_info}>Анкета респондента<div className={styles.email}>{userData.login}</div></div>
                        <div className={styles.survey_progress}>
                            <ProgressBar maxProgress={totalQuestions} progress={totalQuestionsAnswered} color={totalQuestions <= totalQuestionsAnswered ? '#32C665' : '#1192E8'} />
                            <div className={styles.text}>Заполнено <span className='bold'>{totalQuestionsAnswered} / {totalQuestions}</span></div>
                        </div>
                        <div className={styles.topmenu}>
                            <Button type='orangetext' label='Свернуть' onClick={() => expandAll(false)} />
                            <Button type='orangetext' label='Развернуть' onClick={() => expandAll(true)} />
                            <div className={styles.sep}></div>
                            <Button type='orangetext' label='Правила заполнения' onClick={() => setShowRules(true)} />
                        </div>
                    </>
                )}
            </header>
            {userSurvey && userSurvey.status_id == 2 && !assessment.is_finished && (
                <div className={styles.bigmsg}>
                    <BigInfoIcon />
                    <h3>Анкета, которую вы пытаетесь<br/>открыть, недоступна</h3>
                    <p>Пожалуйста, свяжитесь с сотрудником,<br/>который прислал вам эту ссылку</p>
                </div>
            )}
            {userSurvey && assessment && assessment.is_finished && (
                <div className={styles.bigmsg}>
                    <BigInfoIcon />
                    <h3>Сбор электронных анкет<br/>в вашей компании окончен</h3>
                    <p>Вы прошли по ссылке, помеченной статусом<br/>“процедура завершена”. Вероятно,<br/>регламентный срок заполнения анкет истёк.<br/>
                        Вы можете обратиться к администратору<br/>опроса, от которого вы получили данную<br/>
                        ссылку, либо связаться с нами по электронной<br/>почте: <a href="mailto:opros@bssg.ru">opros@bssg.ru</a></p>
                </div>
            )}
            {userSurvey && userSurvey.status_id == 3 && !assessment.is_finished && (
                <div className={styles.bigmsg}>
                    <CheckGreenIcon />
                    <h3>Благодарим вас за участие<br/>в опросе</h3>
                    <p>Мы получили ваши ответы и обработаем их,<br/>чтобы подсчитать оценку вашей компании</p>
                </div>
            )}

            {userSurvey && userSurvey.status_id == 1 && !assessment.is_finished && totalQuestions > 0 && totalQuestions <= totalQuestionsAnswered && (
                <div className={styles.notice_done}>
                    <SurveyDoneIcon />
                    <div className={styles.msg}>Заполнены все разделы оценки</div>
                    <Button label='Отправить результаты' onClick={completeSurvey} />
                </div>
            )}
            {data.kind_id == 0 && userSurvey && userSurvey.status_id == 1 && !assessment.is_finished && (
                <div className={styles.scales}>
                    <div className={styles.head} onClick={() => setScalesExpanded(!scalesExpanded)}>
                        {scalesExpanded ? (<TglOpenedIcon />) : (<TglClosedIcon />)}
                        <label>Варианты оценки</label>
                    </div>
                    {scales.map(scale => <div key={'scale-' + scale.id} className={classNames(styles.scale, {[styles.active]: activeScaleId == scale.id})}><div className={styles.title}>{scale.name}</div>{scalesExpanded && (<div className={styles.hint}>{scale.hint}</div>)}</div>)}
                </div>
            )}
            {userSurvey && userSurvey.status_id == 1 && !assessment.is_finished && (
                <div className='page transparent scrollable'>
                    {data && data.sections && data.sections.map(section => 
                        <SurveySection key={'section-' + section.id} expanded={isExpanded[section.id ?? '']} section={section} 
                            onExpandedChanged={(v) => { setIsExpanded((prev:any) => ({...prev, [section.id ?? '']: v})) }} 
                            onAnswerSelected={(qid, aid) => answerSelected(section.id ?? 0, qid, aid)} 
                            onAnswerHover={(answer:AnswerData) => { setActiveScaleId(answer.scale_id ?? -1) }}
                            onCommentAnswer={(qid, aid, comment) => onCommentAnswer(section.id ?? 0, qid, aid, comment)}
                            isSimple={data.kind_id == 0} /> 
                    )}
                    
                </div>
            )}
            {userSurvey && userSurvey.status_id == 1 && !assessment.is_finished && totalQuestions > totalQuestionsAnswered && (
                    <div className={styles.notice_done}>
                        <InfoIcon />
                    <div className={styles.msg}>Когда будут заполнены все разделы оценки, вы сможете отправить результаты</div>
                    </div>
                )}
            <ModalDlg show={showRules} title='Правила заполнения' onClose={() => setShowRules(false)} showSubmitBtn={false} cancelBtnText='Закрыть'>
                <div dangerouslySetInnerHTML={{__html: data.rules_html ?? ''}}>
                    
                </div>
            </ModalDlg>
        </>
    )
}