import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import styles from './AssessmentActions.module.scss';
import classNames from 'classnames';
import { AssessmentData } from '../../types/assessment';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as DropDownIcon } from '../../assets/img/dropdown-toggle.svg';
import { listRates } from '../../services/rate';
import { handleHttpError } from '../../services/api';
import { RateData } from '../../types/rate';
import { deleteAssessment, updateAssessment } from '../../services/assessment';
import { raiseNotice } from '../Notices/Notices';
import { ModalDlg } from '../ModalDlg';
import { TextInput } from '../TextInput';
import { validateRequired } from '../../services/utils';

type AssessmentActionsProps = {
    assessment: AssessmentData;
    className?: string;
    onActionDone?: (action: string) => void;
};

export const AssessmentActions: FC<AssessmentActionsProps> = ({ assessment, className, onActionDone }) => {
    const [rates, setRates] = useState<RateData[]|undefined>(undefined);
    const [showDelDlg, setShowDelDlg] = useState<boolean>(false);
    const [delObjData, setDelObjData] = useState<any>({ id: undefined, name: '' });
    const [showRenameDlg, setShowRenameDlg] = useState<boolean>(false);
    const [showRenameValidation, setShowRenameValidation] = useState<boolean>(false);
    const [renameObjData, setRenameObjData] = useState<any>({ id: undefined, name: '' });
    const [showFinishDlg, setShowFinishDlg] = useState<boolean>(false);

    useEffect(() => {
        listRates(0, 1000).then(res => {
            setRates(res);
        }).catch(handleHttpError);
    }, []);

    const delAssessment = (id: number, name: string) => {
        deleteAssessment(id).then(res => {
            setShowDelDlg(false);
            raiseNotice('info', 'Опрос ' + name + ' удалён');
            if (onActionDone)
                onActionDone('delete');
        }).catch(handleHttpError);
    };

    const renameAssessment = () => {
        setShowRenameValidation(true);

        if (renameObjData.id && renameObjData.name) {
            updateAssessment({ id: renameObjData.id, name: renameObjData.name }).then(res => {
                
                setShowRenameDlg(false);
                raiseNotice('info', 'Опрос переименован');
                if (onActionDone)
                    onActionDone('rename');
            }).catch(handleHttpError);
        }
    };

    const finishAssessment = () => {
        updateAssessment({...assessment, is_finished: true}).then(res => {
            if (res.id) {
                setShowFinishDlg(false);
                raiseNotice('info', 'Опрос завершен');
                if (onActionDone)
                    onActionDone('finish');
            }
        }).catch(handleHttpError);
    }

    return (
        <>
            <Dropdown className={classNames(styles.btn, className)}>
                <Dropdown.Toggle>Действия с опросом <DropDownIcon /></Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => { setRenameObjData({ id: assessment.id, name: assessment.name }); setShowRenameValidation(false); setShowRenameDlg(true); }}>Переименовать опрос</Dropdown.Item>
                    {!assessment.is_paid && (<Dropdown.Item>Сформировать счет</Dropdown.Item>)}
                    <Dropdown.Item onClick={() => { setDelObjData({ id: assessment.id, name: '<span class="bold">' + assessment.name + '</span>, тариф ' + rates?.find(r => r.id == assessment.rate_id)?.name }); setShowDelDlg(true); }}>Удалить опрос</Dropdown.Item>
                    {!assessment.is_finished && (<Dropdown.Item onClick={() => { setShowFinishDlg(true); }}>Завершить опрос</Dropdown.Item>)}
                </Dropdown.Menu>
            </Dropdown>
            <ModalDlg show={showDelDlg} onClose={() => setShowDelDlg(false)} title='Удаление опроса' submitBtnText='Удалить' onSubmit={() => delAssessment(delObjData.id, delObjData.name)}>
                <div dangerouslySetInnerHTML={{__html:`Подтвердите удаление опроса ${delObjData.name}`}}></div>
            </ModalDlg>
            <ModalDlg show={showRenameDlg} onClose={() => setShowRenameDlg(false)} title='Переименование опроса' submitBtnText='Переименовать' onSubmit={renameAssessment}>
                <TextInput label='Название опроса' isRequired showValidationError={showRenameValidation} validate={validateRequired} value={renameObjData.name} onChange={(e) => setRenameObjData((prev:any) => ({...prev, name: e.target.value}))} />
            </ModalDlg>
            <ModalDlg show={showFinishDlg} onClose={() => setShowFinishDlg(false)} title='Завершение опроса' submitBtnText='Завершить' onSubmit={finishAssessment}>
                <div dangerouslySetInnerHTML={{__html:`Подтвердите завершение опроса ${assessment.name}`}}></div>
            </ModalDlg>
        </>
    );
};