import classNames from 'classnames';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import styles from './SurveySection.module.scss';
import { ReactComponent as TglOpenedIcon } from '../../assets/img/section-tgl-opened.svg';
import { ReactComponent as TglClosedIcon } from '../../assets/img/section-tgl-closed.svg';
import { ReactComponent as InfoIcon } from '../../assets/img/info-orange.svg';
import { AnswerData, SectionData } from '../../types/survey';
import { Button } from '../Button';
import { ProgressBar } from '../ProgressBar';
import { TextArea } from '../TextArea';
import { commentAnswer } from '../../services/survey';

type SurveySectionProps = {
    expanded?: boolean;
    section: SectionData;
    onExpandedChanged?: (v: boolean) => void;
    onAnswerSelected?: (question_id: number, answer_id: number) => void;
    isSimple?: boolean;
    onAnswerHover?: (answer: AnswerData) => void;
    onCommentAnswer?: (question_id: number, answer_id: number, comment: string) => void;
};

export const SurveySection: FC<SurveySectionProps> = ({ expanded = true, section, onExpandedChanged, onAnswerSelected, isSimple = false, onAnswerHover, onCommentAnswer }) => {
    const [totalQuestions, setTotalQuestions] = useState<number>(0);
    const [totalQuestionsAnswered, setTotalQuestionsAnswered] = useState<number>(0);
    const [showQuestionComment, setShowQuestionComment] = useState<number|undefined>(undefined);
    const [questionComment, setQuestionComment] = useState<string>('');

    const selectAnswer = (question_id: number, answer_id: number) => {
        if (onAnswerSelected)
            onAnswerSelected(question_id, answer_id);
    }

    useEffect(() => {
        setTotalQuestions(section.questions?.length ?? 0);
        setTotalQuestionsAnswered(section.questions?.filter(q => q.answers?.some(a => a.selected)).length ?? 0);
    }, [ section ]);

    const postCommentAnswer = (question_id: number, answer_id: number) => {
        if (onCommentAnswer)
            onCommentAnswer(question_id, answer_id, questionComment);
        setQuestionComment('');
        setShowQuestionComment(undefined);
    }

    return (
        <>
        <div className={styles.survey_section_header} onClick={onExpandedChanged ? () => onExpandedChanged(!expanded) : undefined}>
            <div className={styles.toggle}>{expanded ? (<TglOpenedIcon />) : (<TglClosedIcon />)}</div>
            <div className={styles.name}>{section.number} {section.name}</div>
            <div className={styles.section_progress}>
                <div className={styles.text}>Заполнено <span className='bold'>{totalQuestionsAnswered} / {totalQuestions}</span></div>
                <div className={styles.progress2}>
                    <ProgressBar maxProgress={totalQuestions} progress={totalQuestionsAnswered} color={totalQuestions <= totalQuestionsAnswered ? '#32C665' : '#1192E8'} />
                </div>
            </div>
        </div>
        {expanded && (
            <div className={classNames(styles.survey_section_body, { [styles.shown]: expanded })}>
                {section.questions?.map((q) => (<div key={'s-' + q.survey_id + '-q-' + q.id} className={styles.question}>
                    <div className={styles.q}>
                        <div className={classNames(styles.stripe, {[styles.active]: q.answers?.some(a => a.selected)})}></div>
                        {q.number} {q.name}
                        {q.answers?.some(a => a.selected) && (
                        <div className={styles.comment}>
                            <a className={styles.btn_comment} onClick={() => { setShowQuestionComment(q.id); setQuestionComment(q.answers?.find(a => a.selected)?.comment ?? ''); }}>{q.answers?.find(a => a.selected)?.comment ? <><InfoIcon />Открыть комментарий</> : 'Комментировать ответ'}</a>
                            <div className={classNames(styles.comment_popup, {[styles.shown]: showQuestionComment == q.id})}>
                                <TextArea value={questionComment} onChange={(e) => setQuestionComment(e.target.value)} placeholder='Введите текст комментария' />
                                <div className={styles.btns}>
                                    <Button label='Сохранить' onClick={() => postCommentAnswer(q.id ?? 0, q.answers?.find(x => x.selected)?.id ?? 0)} />
                                    <Button label='Отменить' type='empty' onClick={() => setShowQuestionComment(undefined)} />
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                    
                    {q.answers?.map((ans:AnswerData) => (
                        <div key={'s-' + q.survey_id + '-a-' + ans.id} className={classNames(styles.answer, {[styles.simple]: isSimple, [styles.selected]: ans.selected})} onMouseEnter={(e) => { if (onAnswerHover) onAnswerHover(ans); }} onClick={() => selectAnswer(q.id ?? 0, ans.id ?? 0)}>
                            {isSimple ? (<div className={styles.ans_selector}></div>) : ans.answer}
                        </div>
                    ))}
                </div>))}
            </div>
        )}
        </>
    );
};
